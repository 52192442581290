declare var grecaptcha: any;

const url = document.querySelector('.contact').getAttribute('data-url')

function loadScript(src, callback)
{
  var s,
      r,
      t;
  r = false;
  s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = src;
  s.onload = s.onreadystatechange = function() {
    //console.log( this.readyState ); //uncomment this line to see which ready states are called.
    if ( !r && (!this.readyState || this.readyState == 'complete') )
    {
      r = true;
      callback();
    }
  };
  document.body.append(s);
}
function insertScript(src){
  var s,
  r,
  t;
  r = false;
  s = document.createElement('script');
  s.src = src;
  document.body.append(s);
}

function initTel(){
  const phone = document.querySelector("#phone");

  (<any>window).intlTelInput(phone, {
    utilsScript: url +"/assets/plugins/intl-tel-input/utils.js",
    initialCountry: "auto",
    geoIpLookup: callback => {
      fetch("https://ipapi.co/json")
        .then(res => res.json())
        .then(data => callback(data.country_code))
        .catch(() => callback("us"));
    },
  });
}


function initObserver(){
  let options = {
    threshold: 0.2,
  };

  let observer = new IntersectionObserver(initDefer, options);
  let target = document.querySelector(".contact");
  observer.observe(target);
}

function initDefer(entries, observer){
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      loadScript(url+'/assets/plugins/intl-tel-input/intlTelInput.min.js',initTel)
      insertScript('https://www.google.com/recaptcha/api.js?onload=initCaptcha&render=explicit')
      observer.unobserve(document.querySelector(".contact"))
    }
  });
}

initObserver()
